import { graphql } from 'gatsby'
import React from 'react'
import Catalogue from '~containers/Catalogue'

const CataloguePage = ({ data }) => <Catalogue {...data} />

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cards: allMongodbRefhelpCards {
      edges {
        node {
          type
          title
          shortDescr
          id
          mongoId: mongodb_id
        }
      }
    }
  }
`

export default CataloguePage
