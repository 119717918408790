export const CATEGORIES = [
  'house',
  'clothes',
  'food',
  'animals',
  'babies',
  'medicine',
]

export const LOCATIONS = [
  'ukraine',
  'lviv',
  'kyiv',
  'poland',
  'krakow',
  'warsaw',
]
