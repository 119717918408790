import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cards from '~components/Cards'
import Layout from '~components/Layout'
import S from '~components/seo'
import { Container } from 'react-bootstrap'
import useHttp from '~hooks/useHttp'
import Filter from './Filter'

import { catalogue } from './Catalogue.module.scss'

const Catalogue = () => {
  const [cards, setCards] = useState([])
  const [filter, setFilter] = useState({ location: 'ukraine' })
  const { request } = useHttp()

  const getCards = async () => {
    const list = await request(`https://refhelpapp.herokuapp.com/cards`, 'GET')
    setCards(list)
  }

  useEffect(() => {
    getCards()
  }, [])
  const { t } = useTranslation()
  return (
    <Layout>
      <S title={t(`titles.catalogue`)} />
      <Filter filter={filter} setFilter={setFilter} />
      <Container className={catalogue}>
        <Cards list={cards} filter={filter} />
      </Container>
    </Layout>
  )
}

export default Catalogue
