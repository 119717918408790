import React from 'react'
import { Container, Dropdown } from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { CATEGORIES, LOCATIONS } from './constants'

import * as s from './Filter.module.scss'

const Filter = ({ filter, setFilter }) => {
  const { t } = useTranslation()
  const handleCategory = (type) => {
    setFilter((prev) => ({ ...prev, type }))
  }

  const handleLocation = (location) => {
    setFilter((prev) => ({ ...prev, location }))
  }
  return (
    <Container as="section" className={s.filter}>
      <Dropdown className="filter">
        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
          {filter.type ? t(`categories.${filter.type}`) : t(`categories.title`)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <DropdownItem
            className={cn({ active: !filter.type })}
            onClick={() => {
              handleCategory('')
            }}
          >
            {t(`categories.title`)}
          </DropdownItem>
          {CATEGORIES.map((el) => (
            <DropdownItem
              className={cn({ active: filter.type === el })}
              onClick={() => {
                handleCategory(el)
              }}
            >
              {t(`categories.${el}`)}
            </DropdownItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="filter">
        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
          {t(`locations.${filter.location}`)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {LOCATIONS.map((el) => (
            <DropdownItem
              className={cn({ active: filter.location === el })}
              onClick={() => {
                handleLocation(el)
              }}
            >
              {t(`locations.${el}`)}
            </DropdownItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  )
}

export default Filter
